import React, { useState, useEffect } from 'react';

import '../../styles/ControlPanel.css';
import Tabs from './cardBodyTabs.jsx';
import CommandHistory from '../testData/CommandHistory.jsx';
import { getTestRunTimeSeries } from '../../services/apiService.js';
import { getTestPreviewGraphData } from '../../services/apiService.js';
import AccordionGraph from './accordionGraph.jsx';


const CardBodyHome = ({ data, schedules }) => {
    const tr = schedules.length > 0 && schedules[0].hasOwnProperty("testRuns") ? schedules[0].testRuns[0] : undefined;

    const [fullLog, setFullLog] = useState({});
    const [timeSeriesData, setTimeSeriesData] = useState([]);
    const [testLogs, setTestLogs] = useState([]);
    const [formattedData, setFormattedData] = useState([]);
    const [targetVolume, setTargetVolume] = useState([]);
    const [targetRate, setTargetRate] = useState([]);
    const [testStart, setTestStart] = useState([]);

    if (tr) {
        useEffect(() => {
            async function fetchData() {
                try {
                    const apiData = await getTestRunTimeSeries({ tr_id: tr.id });
                    console.log('API Data:', apiData);
                    setFullLog(apiData);
                } catch (error) {
                    console.error('Error fetching API data:', error);
                }
            }

            if (tr.id) {
                fetchData();
            }
        }, [tr.id]);

        useEffect(() => {
            async function fetchData() {
                try {
                    const apiData = await getTestPreviewGraphData({ tr_id: tr.id });
                    console.log('Graph Data:', apiData);
                    setTimeSeriesData(apiData);
                } catch (error) {
                    console.error('Error fetching API data:', error);

                }
            }

            if (tr.id) {
                fetchData();
                //const testLogs = timeSeriesData.testLogs; 

            }
        }, [tr.id]);
    }

    useEffect(() => {
        console.log("Time series data updated: ", timeSeriesData);
        const testLogTemp = timeSeriesData.hasOwnProperty("testLogs") ? timeSeriesData.testLogs : undefined;
        const targetVolumeTemp = timeSeriesData.hasOwnProperty("delivery_vtbi") ? parseFloat(timeSeriesData.delivery_vtbi) : undefined;
        const targetRateTemp = timeSeriesData.hasOwnProperty("delivery_rate") ? parseFloat(timeSeriesData.delivery_rate) : undefined;
        const testStartTemp = timeSeriesData.hasOwnProperty("test_start_time") ? new Date(timeSeriesData.test_start_time) : undefined;
        setTestLogs(testLogTemp);
        setTargetVolume(targetVolumeTemp);
        setTargetRate(targetRateTemp);
        setTestStart(testStartTemp);
    }, [timeSeriesData]);

    useEffect(() => {
        console.log("Test logs updated: ", testLogs);
        console.log("VTBI, Rate, Start Time: ", targetVolume, targetRate, testStart);
    }, [testLogs]);


    useEffect(() => {
        if (testLogs) {
            console.log('Processing testLogs:', testLogs); // Log raw testLogs data
            const filteredLogs = testLogs.filter(log => {
                const hasRequiredFields = log.event_timestamp && log.event_data && log.event_data.delivery_vol_delivered !== undefined && log.event_data.delivery_vol_delivered_raw !== undefined;
                if (!hasRequiredFields) {
                    console.warn('Skipping log due to missing fields:', log); // Warn about logs with missing fields
                }
                return hasRequiredFields;
            });

            console.log('Filtered logs:', filteredLogs); // Log filtered data

            if (filteredLogs.length > 0) {
                const data = filteredLogs.map(log => {
                  const time = new Date(log.event_timestamp);
                  const actual_vol_delivered_ml = parseFloat(log.event_data.delivery_vol_delivered, 10);
                  const volume_measured = parseFloat(log.event_data.delivery_vol_delivered_raw, 10);
                  if (isNaN(time.getTime()) || isNaN(actual_vol_delivered_ml) || isNaN(volume_measured)) {
                    console.warn('Invalid data detected and skipped:', { time, actual_vol_delivered_ml, volume_measured });
                    return null;
                  }
                  return {
                    time,
                    actual_vol_delivered_ml,
                    volume_measured
                  };
                }).filter(d => d !== null); // Filter out invalid values
                console.log('Formatted data:', data); // Log formatted data
                setFormattedData(data);
              } else {
                console.warn('No valid data after filtering.'); // Warn if no valid data is found
                setFormattedData([]);
              }
            } else {
              console.warn('testLogs is undefined or empty.'); // Warn if testLogs is undefined or empty
              setFormattedData([]);
            }
          }, [testLogs]);

    const tabs = [
        {
            label: 'Software Versions', content:
                <div>
                    <div className="card-body-status-item">
                        <span>FCL Version: {data.fcl_version}</span>
                        <span>STM32 Version: {data.stm32_m4_version}</span>
                        <span>i.MX7 M4 Version: {data.imx7_m4_version}</span>
                    </div>
                </div>
        },
        {
            label: 'Graph Preview', content:
                <div>
                    {testLogs ? (
                        formattedData.length > 0 ? (
                            <AccordionGraph 
                                data={formattedData}
                                targetVolume={targetVolume} 
                                targetRate={targetRate} 
                                testStart={testStart} />
                        ) : (
                            <p>No active test data</p>
                        )
                    ) : (
                        <p>No active test data</p>
                    )}
                </div>
        },
        
    ];

    return (
        <div className="card-body-container">
            <div className="card-body-left">
                {tr ? (

                    <div className="card-status-box">
                        <div className="card-body-status-item" style={{ textAlign: "center" }}>
                            <h5>TEST {tr.test_number}</h5>
                            <h6>SERIES {tr.test_series}</h6>
                            <div className="card-body-status-details">
                                <span className="label">Target Rate:</span><span className="value">{String(tr.delivery_rate)} mL/Hr</span>
                                <span className="label">Target VTBI:</span><span className="value">{String(tr.delivery_vtbi)} mL</span>
                                <span className="label">Vol Measured:</span><span className="value">{String(tr.delivery_vol_delivered_raw)} mL</span>
                                <span className="label">Balance Vol:</span><span className="value">{tr.actual_vol_delivered_ml ? String(Number(tr.actual_vol_delivered_ml).toFixed(2)) : ""} mL</span>
                                <span className="label">Meas Error:</span><span className="value">{tr.measurement_error_percent ? String((tr.measurement_error_percent).toFixed(2)) : ""} {tr.measurement_error_percent ? "%" : "--"}</span>
                                <span className="label">Total Error:</span><span className="value">{tr.total_error_percent ? String((tr.total_error_percent).toFixed(2)) : ""} {tr.total_error_percent ? "%" : "--"}</span>
                                <span className="label">Drop Vol AVG:</span><span className="value">{String(tr.drops_vol_avg)} uL</span>
                                <span className="label">Water Level:</span><span className="value">{String(tr.water_level)}</span>
                                <span className="label">Drops Detected:</span><span className="value">{String(tr.drops_detected_upper)}</span>
                            </div>
                        </div>
                    </div>

                ) : (

                    <div className="card-status-box">
                        <div className="card-body-status-item">
                            <h5>No Active Test Data</h5>
                        </div>
                    </div>

                )}
            </div>
            <div className="card-body-right">
                <Tabs tabs={tabs} />
            </div>
        </div>
    );
};

export default CardBodyHome;