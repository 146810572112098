import React, { useEffect, useState } from 'react';
import { getSeparatedTestStats } from '../../services/apiService.js';
import * as XLSX from 'xlsx';
import { Button } from 'react-bootstrap';
import DownloadIcon from '@mui/icons-material/Download';

const TestDataStatsBar = ({ testStats, testFilter, setTestFilter }) => {

    const [totalError, setTotalError] = useState(false)
    const [measError, setMeasError] = useState(false)
    const [noFlow, setNoFlow] = useState(false)
    const [boundaryRatio, setBoundaryRatio] = useState(false)
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [sepTestStats, setSepTestStats] = useState({});
    const currDate = new Date();

    useEffect(() => {
        if (testFilter.test_start_date) {
            setCustomStartDate(new Date(testFilter.test_start_date));
        } else {
            let startDate = new Date(currDate);
            startDate.setDate(startDate.getDate() - testFilter.startDateOffset);
            setCustomStartDate(startDate);
        }

        if (testFilter.test_end_date) {
            setCustomEndDate(new Date(testFilter.test_end_date));
        } else {
            setCustomEndDate(currDate);
        }
    }, [testFilter]);

    useEffect(() => {
        console.log("Start, End: ", customStartDate, customEndDate)
    }, [customStartDate]);

    useEffect(() => {

        let newTotalError = testStats.total_error_percent || false
        let newMeasError = testStats.measurement_error_percent || false
        let newNoFlow = testStats.max_noflow_time_seconds || false
        let newBoundaryRatio = testStats.droplog_boundary_ratio_avg || false

        setTotalError(newTotalError)
        setMeasError(newMeasError)
        setNoFlow(newNoFlow)
        setBoundaryRatio(newBoundaryRatio)

    }, [testStats]);

    const unitMap = {
        total_error_percent: '%',
        measurement_error_percent: '%',
        drops_vol_avg: 'mL',
        droplog_boundary_ratio_avg: '',
        max_noflow_time_seconds: ''
    }

    const renderStats = (colName, colTitle, data) => {

        const unit = unitMap[colName] || ''

        const min = data.min ? `${data.min}${unit}` : ''
        const max = data.max ? `${data.max}${unit}` : ''
        const avg = data.avg ? `${data.avg}${unit}` : ''
        const med = data.med ? `${data.med}${unit}` : ''
        const stdDev = !isNaN(Number(data.stdDev)) ? `${2 * Number(data.stdDev)}${unit}` : ''
        const quartileCounts = data.quartileCounts ? `${data.quartileCounts}${unit}` : ''

        return (

            <span>
                <strong className="stats-title">{colTitle}</strong> {`[ `}
                <span className="stats-value">
                    {min}</span> , <span className="stats-value">{max}</span> {` ] `}
                <span className="stats-symbol">x̄</span> <span className="stats-value" title="Sample Mean">{avg}</span>
                <span className="stats-symbol" title="Standard Deviation">{`2σ`} </span><span className="stats-value" title="2 * Sample Standard Deviation">{stdDev}</span>
            </span>

        );
    };

    const handleDownload = async () => {
        try {
            const params = {
                startTime: customStartDate,
                endTime: customEndDate
            };

            console.log('Request Params:', params);

            const response = await getSeparatedTestStats(params);
            console.log('API Response:', response);

            // Flatten the response data
            const dataToExport = flattenData(response);
            console.log('Data to export:', dataToExport);

            if (!Array.isArray(dataToExport) || dataToExport.length === 0) {
                throw new Error('Invalid data format or no data available');
            }

            // Convert data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(dataToExport);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Stats");

            // Make the header row sticky and enable filtering
            worksheet['!autofilter'] = { ref: "A1:Z1" }; // Adjust the range as needed

            // Create a buffer from the workbook
            const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            // Create a Blob from the buffer
            const blob = new Blob([buffer], { type: 'application/octet-stream' });

            // Create a link element and trigger the download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'stats.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error generating Excel file:', error.message);
        }
    };

    // Function to flatten the JSON structure
    const flattenData = (data) => {
        const result = [];

        Object.keys(data).forEach(testName => {
            const [serialNumber, rate, ...fluidArray] = testName.split('-');
            const fluid = fluidArray.join('-');

            const metrics = data[testName];
            Object.keys(metrics).forEach(metricName => {
                const metricValues = metrics[metricName];
                const flatObject = {
                    testName,      // Include the original testName
                    serialNumber,
                    rate,
                    fluid,
                    metricName,
                    ...metricValues,
                    ...metricValues.quartileCounts
                };
                delete flatObject.quartileCounts;
                result.push(flatObject);
            });
        });

        return result;
    };


    return (
        <div className="stats-bar-container" >
            {totalError ? renderStats("total_error_percent", "Total Error", totalError) : <></>}
            {measError ? renderStats("measurement_error_percent", "Meas Error", measError) : <></>}
            {noFlow ? renderStats("max_noflow_time_seconds", "Longest NoFlow", noFlow) : <></>}
            {boundaryRatio ? renderStats("droplog_boundary_ratio_avg", "Boundary Ratio Avg", boundaryRatio) : <></>}
            <Button variant="secondary" size="sm" style={{ padding: '0.25rem 0.25rem', fontSize: '0.5rem'  }} onClick={handleDownload}>
                <DownloadIcon style={{ fontSize: '1rem', height: '1rem', width: '1rem' }}/> 
            </Button>
        </div>

    );
};

export default TestDataStatsBar;
