import React, { useState } from 'react';
import '../../styles/ControlPanel.css';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import IconButton from '@mui/material/IconButton';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AddIcon from '@mui/icons-material/Add';
import StopIcon from '@mui/icons-material/Stop';
import Tooltip from '@mui/material/Tooltip';
import CardStatusBar from './cardStatusBar.jsx';
import CardBodyHome from './cardBodyHome.jsx';
import Popup from './scheduleCreation.jsx';

const TestKitRow = ({ data, schedules, kitDetails }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const debugRow = (Number(data.id) > 55);
    const [show, setShow] = useState(false);

    const handleShow = async () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    const getStatusClassName = (status) => {
        if (status === null || status === undefined) {
            return 'text-status';  // Show '--' with 'text-status' class if status is null or undefined
        }
    
        return status.toLowerCase() === 'delivering' 
            ? 'text-status-green' 
            : status.toLowerCase() === 'no program'
                ? 'text-status' 
                : 'text-status-red';
    };

    const getErrorCodeClassName = (errorcode) => {
        return errorcode && errorcode.toLowerCase() !== '0x0' && errorcode !== '0' ? 'text-alert-red' : '';
    };

    const formatSecondsToHHMMSS = (seconds) => {
        const pad = (num) => String(num).padStart(2, '0');
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);
        return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
    };

    const getDeliveryTimeRemaining = (schedules) => {
        // Loop through the schedules array
        for (let i = 0; i < schedules.length; i++) {
            // Check if the current schedule has the status "SCHEDULED"
            if (schedules[i].test_schedule_status === "SCHEDULED" && schedules[i].testRuns) {
                const testRuns = schedules[i].testRuns;
                const lastTestRun = testRuns[testRuns.length - 1];

                // Check if lastTestRun and delivery_time_remaining exist
                if (lastTestRun && typeof lastTestRun.delivery_time_remaining === 'number') {
                    const deliveryTimeRemaining = lastTestRun.delivery_time_remaining;
                    return formatSecondsToHHMMSS(deliveryTimeRemaining);
                }
            }
        }

        // Return a default value or error message if no suitable schedule is found
        return '00:00:00';
    };

    const deliveryTime = getDeliveryTimeRemaining(schedules);

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const iconWrapperStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '5px',
        cursor: 'pointer',
    };

    const rowHeader = (data) => {
        return (
            <div className="header-acc-cell" style={{ gridColumnStart: 1, gridColumnEnd: 4, gridRowStart: 1, gridRowEnd: 1 }} >
                <span className="acc-header-cell">
                    <div className="acc-header-content">
                        <span className="vertical-acc-cell">
                            <div>
                                {data.alarm_status && data.alarm_status !== 'None' && data.alarm_status !== '0' && (
                                    <Tooltip title={`${data.alarm_status}`} arrow>
                                        <div
                                            style={iconWrapperStyle}
                                            onClick={stopPropagation}
                                            onMouseEnter={stopPropagation}
                                            onMouseLeave={stopPropagation}
                                        >
                                            <WarningAmberIcon style={{ color: '#ffc107' }} onClick={stopPropagation} />
                                        </div>
                                    </Tooltip>
                                )}
                                {data.errorcode && data.errorcode !== '0' && data.errorcode !== '0x0' && (
                                    <Tooltip title={`${data.errorcode}`} arrow>
                                        <div
                                            style={iconWrapperStyle}
                                            onClick={stopPropagation}
                                            onMouseEnter={stopPropagation}
                                            onMouseLeave={stopPropagation}
                                        >
                                            <PriorityHighIcon style={{ color: 'red' }} />
                                        </div>
                                    </Tooltip>
                                )}
                                {data.delivery_status === "Delivering" && (
                                    <Tooltip title={`FCL Stop (Disabled)`} arrow>
                                        <div
                                            style={iconWrapperStyle}
                                            onClick={stopPropagation}
                                            onMouseEnter={stopPropagation}
                                            onMouseLeave={stopPropagation}

                                        >
                                            <IconButton
                                                aria-label="stop"
                                                size="small"
                                                disabled="true"
                                            >
                                                <StopIcon style={{ color: 'red', fontSize: '30px' }} />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </span>
                        <h5><span className={data.usb_status_imx7 ? "safeText" : "dangerText"}>{data.usb_status_imx7 ? "↑" : "↓"}</span> <a className="linkText" href={"ssh://amgistest@" + data.testcl_ip} >TK-{data.id}</a></h5>
                        <span className="vertical-acc-cell">
                            <div>
                                <h5><span></span><a className="linkText" href={"ssh://root@" + data.infdev_ip} >{data.infdev_sn || ""}</a></h5>
                            </div>
                        </span>
                        <span className="vertical-acc-cell">
                            <div>
                                <span className={getStatusClassName(data.delivery_status)}>
                                    {data.delivery_status === null || data.delivery_status === undefined ? '--' : data.delivery_status}
                                </span>
                            </div>
                        </span>

                        <span className="vertical-acc-cell">
                            <div>
                                <span>{deliveryTime}</span>
                            </div>
                        </span>
                        <span className="vertical-acc-cell">
                            <div>
                                <span>{data.usb_status_balance ? (data.balance_reading ? `${data.balance_reading} g` : "No Reading") : <span className="dangerText"> ↓</span>} </span>
                            </div>
                        </span>
                        <span className="vertical-acc-cell">
                            <div>
                                <span></span>
                                <Tooltip title={`New Schedule`} arrow>
                                    <div onClick={stopPropagation}>
                                        <IconButton
                                            aria-label="add"
                                            size="small"
                                            onClick={handleShowPopup}>
                                            <AddIcon style={{ fontSize: '28px' }} />
                                        </IconButton>
                                    </div>
                                </Tooltip>

                            </div>
                        </span>
                        <span className="vertical-acc-cell">
                            <div onClick={stopPropagation}>
                                <span></span>

                                <IconButton
                                    aria-label="more"
                                    size="small"
                                    onClick={handleShow}>
                                    <MoreHoriz style={{ fontSize: '28px' }} />
                                </IconButton>
                            </div>
                        </span>
                    </div>
                </span>
            </div>
        );
    };

    const handleShowPopup = () => setShowPopup(true);
    const handleClosePopup = () => setShowPopup(false);


    return (
        <div className="data-acc-row">
            {rowHeader(data)}
            <Modal
                show={show}
                onHide={handleClose}
                onClick={stopPropagation}
                backdrop={true}
                className="text-light"

                style={{
                    minWidth: "90vw",

                    '--bs-modal-width': '90vw',
                    minHeight: "90vh",
                    left: '0',
                    overflowX: 'auto',
                    overflowY: 'auto',
                    whiteSpace: 'nowrap',
                }}>
                <Modal.Header closeButton className="card-modal-header text-light ">
                    <CardStatusBar data={data} />
                </Modal.Header>
                <Modal.Body
                    className="bg-dark text-light d-flex justify-content-around text-center"
                    style={{
                        minHeight: "90vh",
                        width: "100%",
                        padding: "0",
                        margin: "0",

                        whiteSpace: 'nowrap'
                    }}>
                    <CardBodyHome data={data} schedules={schedules} />
                </Modal.Body>
                <Modal.Footer className="d-flex bg-dark text-light modal-footer" style={{ width: "100%" }}>
                    <p></p>
                </Modal.Footer>
            </Modal>
            <Popup show={showPopup} onClose={handleClosePopup} kits={[data]} kitDetails={kitDetails} />
        </div>
    );
};

export default TestKitRow;