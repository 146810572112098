import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import TableChartIcon from '@mui/icons-material/TableChart';

export const SubnavTable = ({onChangeView}) => {

  const [show, setShow] = useState(false);

  const handleViewChange = () => onChangeView("FLOWRATE_TABLE");
  
  return (
    <>
      <Button className="btn btn-primary mx-3" onClick={handleViewChange}>
      <TableChartIcon /> 
      </Button>
    </>
  );
};