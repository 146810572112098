import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import "../../styles/Form.css";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { createTestSchedule } from '../../services/apiService.js';
import { Toast, ToastContainer } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const FormError = ({ errorMessage }) => {
  return <p>{errorMessage}</p>;
};

const SuccessMessage = ({ messages, show, onClose }) => {
  return (
    <ToastContainer position="top-center" className="p-3">
      <Toast onClose={onClose} show={show} delay={5000} autohide bg="light">
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body><div dangerouslySetInnerHTML={{ __html: messages }} /></Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

const Popup = ({ show, onClose, kits, kitDetails, updateTestSchedules }) => {
  if (!show) {
    return null;
  }

  // Standardize kitDetails to be an array
  const kitDetailsArray = Array.isArray(kitDetails) ? kitDetails : [kitDetails];

  const defaultValues = kits.reduce((values, kit, index) => {
    const kitDetail = kitDetailsArray[index] || {};
    const tr = Array.isArray(kitDetail.testRuns) && kitDetail.testRuns.length > 0 ? kitDetail.testRuns[0] : {};

    return {
      ...values,
      [`test_series_${index}`]: tr.test_series || '',
      [`test_set_number_${index}`]: tr.test_set_number || '',
      [`needle_gauge_${index}`]: tr.needle_gauge || '',
      [`fluid_type_${index}`]: tr.fluid_type || 'DI Water',
      [`fluid_density_${index}`]: tr.fluid_density || 0.9954,
      [`delivery_rate_${index}`]: '',
      [`delivery_vtbi_${index}`]: '',
      [`starting_bag_vol_${index}`]: '',
      [`container_type_${index}`]: '1',
      [`number_of_tests_${index}`]: '1',
      [`capture_images_num_drops_${index}`]: '15'
    };
  }, {});

  const { register, handleSubmit, setValue, getValues } = useForm({ defaultValues });

  const [locks, setLocks] = useState({
    delivery_rate: true,
    delivery_vtbi: true,
    starting_bag_vol: true,
    container_type: true,
    number_of_tests: true,
  });

  const [targetVolMl, setTargetVolMl] = useState(0);
  const [targetTimeMin, setTargetTimeMin] = useState(0);
  const [warnings, setWarnings] = useState({});
  const [messages, setMessages] = useState('');
  const [showMessagePopup, setShowMessagePopup] = useState(false);

  useEffect(() => {
    const newWarnings = [];

    kits.forEach((kit, index) => {
      const deliveryRate = getValues(`delivery_rate_${index}`);
      const deliveryVtbi = getValues(`delivery_vtbi_${index}`);
      const numberOfTests = getValues(`number_of_tests_${index}`);
      const containerType = getValues(`container_type_${index}`);
      const bagVol = getValues(`starting_bag_vol_${index}`);
      const balanceReading = kit.balance_reading;

      if (deliveryRate > 0 && deliveryVtbi > 0 && numberOfTests > 0) {
        const totalVolume = deliveryVtbi * numberOfTests;
        setTargetVolMl((prev) => ({ ...prev, [index]: totalVolume }));

        const totalTimeHours = totalVolume / deliveryRate;
        setTargetTimeMin((prev) => ({ ...prev, [index]: totalTimeHours * 60 }));

        //const containerVol = containerType == 1 ? 1000 : 0;
        const containerVolumes = {
          1: 1000, // 1L Beaker (PP)
          2: 100,  // 100mL Beaker (PP)
          3: 300   // 300mL Beaker (PP)
        };

        const containerWeights = {
          1: 110, // 1L Beaker (PP)
          2: 9,   // 100mL Beaker (PP)
          3: 24   // 300mL Beaker (PP)
        };
        
        const containerVol = containerVolumes[containerType] || 0;
        const containerWeight = containerWeights[containerType] || 10;

        const warningCondition = (totalVolume > bagVol) ? `TK-${kit.id} Insufficient Fluid`
          : (Number(totalVolume) > Number(containerVol)) ? `TK-${kit.id} Container Overflow Risk`
            : (Number(balanceReading) < Number(containerWeight)) ? `TK-${kit.id} No Container Detected!!!`
              : '';

        if (warningCondition) {
          newWarnings.push(warningCondition);
        }
      }
    });

    setWarnings(newWarnings);
  }, [kits, getValues]);

  const toggleLock = (row) => {
    setLocks((prevLocks) => ({
      ...prevLocks,
      [row]: !prevLocks[row]
    }));
  };

  const handleInputChange = (index, row, value) => {
    if (locks[row]) {
      kits.forEach((_, kitIndex) => {
        setValue(`${row}_${kitIndex}`, value);
      });
    } else {
      setValue(`${row}_${index}`, value);
    }
  };

  const onSubmit = async (data) => {
    const testSchedules = kits.map((kit, index) => ({
      tk_id: kit.id,
      test_series: data[`test_series_${index}`],
      test_set_number: data[`test_set_number_${index}`],
      needle_gauge: data[`needle_gauge_${index}`],
      fluid_type: data[`fluid_type_${index}`],
      fluid_density: data[`fluid_density_${index}`],
      delivery_rate: data[`delivery_rate_${index}`],
      delivery_vtbi: data[`delivery_vtbi_${index}`],
      starting_bag_vol: data[`starting_bag_vol_${index}`],
      container_type: data[`container_type_${index}`],
      number_of_tests: data[`number_of_tests_${index}`],
      capture_images_num_drops: data[`capture_images_num_drops_${index}`]
    }));

    console.log('Submitting test schedules:', testSchedules); // Debugging line

    try {
      const newMessages = [];

      for (const schedule of testSchedules) {
        try {
          const response = await createTestSchedule(schedule);
          console.log('Test schedule created successfully:', response); // Debugging line
          newMessages.push(`Schedule successfully created for TK-${schedule.tk_id}`);
        } catch (error) {
          console.error('Error creating test schedule for TK-', schedule.tk_id, ':', error); // Debugging line
          newMessages.push(`<span style="color: red; font-weight: bold;">Error creating test schedule for TK-${schedule.tk_id}</span>`);
        }
      }

      setMessages(newMessages.join('<br>'));
      setShowMessagePopup(true);
      setTimeout(() => setShowMessagePopup(false), 10000);

      // Update the test schedules in ControlPanel
      if (typeof updateTestSchedules === 'function') {
        updateTestSchedules();
        console.log('Force Update');
      }

      //onClose(); // Close the popup after successful submission
    } catch (globalError) {
      console.error('Unexpected error:', globalError);
      setMessages('Unexpected error occurred while creating test schedules.');
      setShowMessagePopup(true);
      setTimeout(() => setShowMessagePopup(false), 10000);
    }
  };

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content-multi" >
        <button className="close-button" onClick={onClose}><CloseIcon /></button><br />
        <h2> Create Test Schedule </h2>
        <form className="cardForm-multi" onSubmit={handleSubmit(onSubmit)}>
          <table className="form-table">
            <thead>
              <tr>
                <th></th>
                {kits.map((kit, index) => (
                  <th key={index}>TK-{kit.id}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Test Series</td>
                {kits.map((kit, index) => (
                  <td key={index}>
                    <input
                      {...register(`test_series_${index}`)}
                      className="formBoxInputSmall"
                      type="number"
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>Set Number</td>
                {kits.map((kit, index) => (
                  <td key={index}>
                    <input
                      {...register(`test_set_number_${index}`)}
                      className="formBoxInputSmall"
                      type="text"
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>Needle Gauge</td>
                {kits.map((kit, index) => (
                  <td key={index}>
                    <input
                      {...register(`needle_gauge_${index}`)}
                      className="formBoxInputSmall"
                      type="number"
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>Fluid Type</td>
                {kits.map((kit, index) => (
                  <td key={index}>
                    <input
                      {...register(`fluid_type_${index}`)}
                      className="formBoxInputSmall"
                      type="text"
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>Fluid Density</td>
                {kits.map((kit, index) => (
                  <td key={index}>
                    <input
                      {...register(`fluid_density_${index}`)}
                      className="formBoxInputSmall"
                      step="0.0001"
                      type="number"
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  Rate (mL/hr)
                  {kits.length > 1 && (
                    <IconButton onClick={() => toggleLock('delivery_rate')}>
                      {locks.delivery_rate ? <LockIcon /> : <LockOpenIcon />}
                    </IconButton>
                  )}
                </td>
                {kits.map((kit, index) => (
                  <td key={index}>
                    <input
                      {...register(`delivery_rate_${index}`)}
                      className="formBoxInputSmall"
                      step="0.01"
                      required
                      type="number"
                      onChange={(e) => handleInputChange(index, 'delivery_rate', e.target.value)}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  VTBI (mL)
                  {kits.length > 1 && (
                    <IconButton onClick={() => toggleLock('delivery_vtbi')}>
                      {locks.delivery_vtbi ? <LockIcon /> : <LockOpenIcon />}
                    </IconButton>
                  )}
                </td>
                {kits.map((kit, index) => (
                  <td key={index}>
                    <input
                      {...register(`delivery_vtbi_${index}`)}
                      className="formBoxInputSmall"
                      step="0.01"
                      required
                      type="number"
                      onChange={(e) => handleInputChange(index, 'delivery_vtbi', e.target.value)}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  Bag Contents (ml)
                  {kits.length > 1 && (
                    <IconButton onClick={() => toggleLock('starting_bag_vol')}>
                      {locks.starting_bag_vol ? <LockIcon /> : <LockOpenIcon />}
                    </IconButton>
                  )}
                </td>
                {kits.map((kit, index) => (
                  <td key={index}>
                    <input
                      {...register(`starting_bag_vol_${index}`)}
                      className="formBoxInputSmall"
                      type="number"
                      step="1"
                      onChange={(e) => handleInputChange(index, 'starting_bag_vol', e.target.value)}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  Container Type
                  {kits.length > 1 && (
                    <IconButton onClick={() => toggleLock('container_type')}>
                      {locks.container_type ? <LockIcon /> : <LockOpenIcon />}
                    </IconButton>
                  )}
                </td>
                {kits.map((kit, index) => (
                  <td key={index}>
                    <select
                      {...register(`container_type_${index}`)}
                      className="formBoxInputSmall"
                      defaultValue="1"
                      onChange={(e) => handleInputChange(index, 'container_type', e.target.value)}
                    >
                      <option value="1">1L Beaker (PP)</option>
                      <option value="2">100mL Beaker (PP)</option>
                      <option value="3">300mL Beaker (PP)</option>
                    </select>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  # Tests to Run
                  {kits.length > 1 && (
                    <IconButton onClick={() => toggleLock('number_of_tests')}>
                      {locks.number_of_tests ? <LockIcon /> : <LockOpenIcon />}
                    </IconButton>
                  )}
                </td>
                {kits.map((kit, index) => (
                  <td key={index}>
                    <input
                      {...register(`number_of_tests_${index}`)}
                      className="formBoxInputSmall"
                      type="number"
                      step="1"
                      defaultValue="1"
                      required
                      onChange={(e) => handleInputChange(index, 'number_of_tests', e.target.value)}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>Images (# Drops)</td>
                {kits.map((kit, index) => (
                  <td key={index}>
                    <input
                      {...register(`capture_images_num_drops_${index}`)}
                      className="formBoxInputSmall"
                      type="number"
                      defaultValue="15"
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <div className="schedule-button">
            {warnings.length > 0 ? (
              <div
                className="formWarning"
                style={{ color: 'red', fontWeight: 'bold' }}
              >
                {warnings.map((warning, index) => (
                  <div key={index}>{warning}</div>
                ))}
              </div>
            ) : (
              <button type="submit">
                {`Schedule Tests`}
              </button>
            )}
          </div>
        </form>
      </div>
      <SuccessMessage
        messages={messages}
        show={showMessagePopup}
        onClose={() => setShowMessagePopup(false)}
      />
    </div>
  );
};

export default Popup;