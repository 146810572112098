import React, { useState } from 'react';
import '../../styles/ControlPanel.css'; // Make sure to create this CSS file or add styles accordingly

const Tabs = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].label);

    return (
        
        <div className="tabs-container">
            <div className="tab-content-card">
                {tabs.map((tab) => (
                    activeTab === tab.label ? <div key={tab.label}>{tab.content}</div> : null
                ))}
            </div>
            <div className="tabs">
                {tabs.map((tab) => (
                    <button
                        key={tab.label}
                        className={`tab-button ${activeTab === tab.label ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab.label)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            
        </div>
    );
};

export default Tabs;