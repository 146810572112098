// src/components/Accordion.js
import React, { useState, useRef, useEffect } from 'react';
import '../styles/ControlPanel.css';

const AccordionItem = ({ title, content, isChecked, onCheckboxChange  }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.maxHeight = '0px';
    }
  }, [isOpen]);

  return (
    <div className="accordionItem">
      <div className={`accordionHeader ${isChecked ? 'highlight' : ''}`}>
        <input 
          type="checkbox" 
          className="accordionCheckbox"
          checked={isChecked} 
          onChange={onCheckboxChange}
        />
        <div onClick={toggleAccordion}>
          {title}
        </div>
      </div>
      <div
        ref={contentRef}
        className={`accordionContent ${isOpen ? 'open' : ''}`}
      >
        {content}
      </div>
    </div>
  );
};

const Accordion = ({ items, checkedItems, onCheckboxChange }) => {
  return (
    <div className="accordionComp">
      {items.map((item, index) => (
        <AccordionItem 
          key={index} 
          title={item.title} 
          content={item.content} 
          isChecked={checkedItems[index]} 
          onCheckboxChange={() => onCheckboxChange(index)}
        />
      ))}
    </div>
  );
};
export default Accordion;