import React, { useState, useEffect } from 'react';
import { Dropdown, Form, Row, Col, Container, Button } from 'react-bootstrap';
import GitlabBrowser from '../components/menderArtifactGenerator/GitlabBrowser';
import SwPickListDisplay from '../components/menderArtifactGenerator/SwPickListDisplay';
import BuildProgressDisplay from '../components/menderArtifactGenerator/BuildProgressDisplay';

import { gitlabApi, requestMenderArtifactAssembly, requestArtifactBuildProgress } from '../services/swApiService';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import "../styles/MenderArtifactGenerator.css";

const MenderArtifactGenerator = () => {
    const [gitData, setGitData] = useState({});
    const [swBrowserSelection, setSwBrowserSelection] = useState({});
    const [swPickList, setSwPickList] = useState({});
    const [buildStarted, setBuildStarted] = useState("");
    const [buildProgress, setBuildProgress] = useState(null);
    const [polling, setPolling] = useState(false);

    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();

    const mode = process.env.REACT_APP_MODE;
    const debugMode = mode === "development";

    useEffect(() => {
        async function fetchData(route) {
            try {
                const response = await gitlabApi({ route });
                console.log('got response: ', response);
                // Update gitData based on the route
                if (route === 'available-projects') {
                    setGitData(prevGitData => ({
                        ...prevGitData,
                        'available-projects': response?.['available-projects'] ?? {}
                    }));
                } else if (route.includes('available-branches')) {
                    setGitData(prevGitData => ({
                        ...prevGitData,
                        'available-branches': response?.['available-branches'] ?? {}
                    }));
                } else if (route.includes('available-tags')) {
                    setGitData(prevGitData => ({
                        ...prevGitData,
                        'available-tags': response?.['available-tags'] ?? {}
                    }));
                }
                else if (route.includes('available-releases')) {
                    setGitData(prevGitData => ({
                        ...prevGitData,
                        'available-releases': response?.['available-releases'] ?? {}
                    }));
                } else if (route.includes('available-pipelines')) {
                    setGitData(prevGitData => ({
                        ...prevGitData,
                        'available-pipelines': response?.['available-pipelines'] ?? {}
                    }));
                }
                console.log("gitData: ", gitData)
                // Add additional cases as needed for other routes
            } catch (error) {
                console.error('Error fetching API data: ' + error);
            }
        }

        const { "project-name": projectName, "source-type": sourceType, "source": source, "pipeline": pipeline, "job": job } = swBrowserSelection;

        if (!projectName) {
            fetchData('available-projects');
        } else if (sourceType === 'branch') {
            if (!source) {
                fetchData(`available-branches/${projectName}`)
            } else {
                fetchData(`available-pipelines/${projectName}/${sourceType}/${source}`)
            }
        } else if (sourceType === 'tag') {
            if (!source) {
                fetchData(`available-tags/${projectName}`)
            } else {
                fetchData(`available-pipelines/${projectName}/${sourceType}/${source}`)
            }
        } else if (sourceType === 'release') {
            if (!source) {
                fetchData(`available-releases/${projectName}`);
            }

        }

        // Add additional conditions for other routes as needed

    }, [swBrowserSelection]);

    const handleSubmit = async () => {
        try {
            const username = isAuthenticated ? accounts[0].name : (debugMode ? "debugUser" : "anonymous");
            const payload = { "sw_picklist": swPickList, username };

            const response = await requestMenderArtifactAssembly(payload);
            setBuildStarted(response?.["build_name"] || "");

            if (response?.["build_name"]) {
                setPolling(true);
            }

            console.log('Response from server:', response);
        } catch (error) {
            console.error('Error sending data to server:', error);
        }
    };

    useEffect(() => {
        let interval;
        if (polling && buildStarted) {
            const endTime = Date.now() + 3 * 60 * 1000; // 3 minutes timeout

            const fetchBuildProgress = async () => {
                try {
                    const username = isAuthenticated ? accounts[0].name : (debugMode ? "debugUser" : "anonymous");
                    const response = await requestArtifactBuildProgress({ username: username, build_name: buildStarted });
                    setBuildProgress(response);

                    if (response?.[buildStarted]?.["status"] == 'Done' || Date.now() > endTime) {
                        setPolling(false);
                    }
                } catch (error) {
                    console.error('Error fetching build progress:', error);
                    setPolling(false);
                }
            };

            interval = setInterval(fetchBuildProgress, 2000); // Poll every 2 seconds
        }

        return () => clearInterval(interval);
    }, [polling, buildStarted, accounts]);

    return (
        <Container fluid  style={{ color: 'white', backgroundColor: '#333', padding: '20px'}}>
            <h1>Mender Artifact Generator</h1>

            {!buildStarted ?
                <>
                    <GitlabBrowser 
                        gitData={gitData}
                        setGitData={setGitData}
                        swBrowserSelection={swBrowserSelection}
                        setSwBrowserSelection={setSwBrowserSelection}
                        swPickList={swPickList}
                        setSwPickList={setSwPickList}
                    />
                    <SwPickListDisplay swPickList={swPickList} bs-light />

                    {Object.keys(swPickList).length > 0 && (
                        <Button onClick={handleSubmit} variant="success">
                            Build Artifact
                        </Button>
                    )}
                </>
                :
                <>
                    {buildStarted && buildProgress && buildProgress?.[buildStarted] && (
                        //<pre>{JSON.stringify(buildProgress, null, 2)}</pre>
                        <BuildProgressDisplay buildProgress={buildProgress[buildStarted]} />
                    )}
                </>
            }
        </Container>
    );
};

export default MenderArtifactGenerator;
