import React, { useState, useEffect } from 'react';
import '../../styles/TableData.css';
import DataRow from './DataRow.jsx'
import TestDataFilterBar from './TestDataFilterBar.jsx'
import TestDataStatsBar from './TestDataStatsBar.jsx'
import { getTestDataRange } from '../../services/apiService.js';

export const DataTable = ({  }) => {

    // TODO: Move to database ⬇︎

    const defaultTestFilter = {
        test_start_time: "Last 24 Hours",
        id: "All",
        infdev_sn: "All",
        test_set_number: "All",
        delivery_rate: "All",
        needle_gauge: "All",
        fluid_type: "All",
        alarm_status: "All",
        errorcode: "All",
        test_time_elapsed_seconds: "All",
        measurement_error_percent: "All",
        total_error_percent: "All",
        actual_vol_delivered_ml: "All",
        droplog_boundary_ratio_avg: "All",
        stm8_pri_fw_build: "All",
        stm8_sec_fw_build:"All",
        imx7_m4_version: "All",
        stm32_m4_version: "All",
        a7_sw_version: "All",
        a7_sw_branch:"All",
        fcl_version: "All",
        linux_kernel_ver: "All",
    }

    const defaultGraphFilter = {
        mainGraphActiveFields: {
            balance_ml: true,
            delivery_vol_delivered_raw: true,
            controller_status: true,
            dripchamber_status: true,
            alarm_status: true,
            errorcode: true,
            dc_drop_progress_percent: true
        },
        accuracyGraphActiveFields: {
            measurement_error_percent: true,
            total_error_percent: true,
            controller_status: true,
            dripchamber_status: true,
            alarm_status: true,
            errorcode: true,
            dc_drop_progress_percent: true
        },

        dropGraphActiveFields: {
           
            drops_detected_lower:  true,
            drops_detected_upper:  true,
            drops_delivered:  true,
            drops_measured:  true,
            drops_vol_last:  true,
            dc_drop_progress_percent: true,
            dc_fluid_delta_ml: true, 
            dc_fluid_volume_ml:  true,
            
            controller_status: true,
            dripchamber_status: true,
            alarm_status: true,
            errorcode: true,
        }

    }
  
    // Report Display and Navigation
    const [pageNum, setPageNum] = useState(0);
    const [pageLength, setPageLength] = useState(25);
    const [numMatchingTests, setNumMatchingTests] = useState(0);
   
    // Test Data Objects
    const [testData, setTestData] = useState([])
    const [testStats, setTestStats] = useState({})
    const [startDateOffset, setStartDateOffset] = useState(1);

    // User Configurable Report Settings
    const [testFilter, setTestFilter] = useState(defaultTestFilter)
    const [graphSettings, setGraphSettings] = useState (defaultGraphFilter)
    
    const [uniqueFilterValues, setUniqueFilterValues] = useState({})
    
    useEffect(() => {
        async function fetchData() {
            try {
                console.log(`Fetching Page ${pageNum} | Filter: `, testFilter)
                const apiData = await getTestDataRange({...testFilter, pageNum: pageNum, pageLength: pageLength});
                
                setTestData(apiData.data || []);
                setNumMatchingTests(parseInt(apiData.totalCount) || 0)
                setTestStats(apiData.testStats)
                setUniqueFilterValues(apiData.uniqueFilterValues || {})

                console.log("Got test records: ", numMatchingTests)
                console.log("Unique filter values: ", apiData.uniqueFilterValues)

            } catch (error) {
                console.error('Error fetching API data: ' + error);
            }
        }

        
        fetchData();

    }, [pageNum, testFilter, startDateOffset, graphSettings]);
    


    return (
        
        <div>
        
        { testData && !isNaN(pageNum) && testFilter ?
        <>
         <TestDataFilterBar key={JSON.stringify(testFilter)} testStats={testStats}
         testFilter={testFilter}
         testData={testData}
         setTestData={setTestData}
         setTestFilter={setTestFilter}
         pageNum={pageNum} setPageNum={setPageNum}
         pageLength={pageLength}
         numMatchingTests={numMatchingTests}
         uniqueFilterValues={uniqueFilterValues}
         defaultTestFilter={defaultTestFilter}
         />

        <TestDataStatsBar testStats={testStats} testFilter={testFilter} sampleSize={numMatchingTests} />

        <div className="data-table">
       
        { testData.length >= 1 ?
        testData.map((data, index) => (
            <DataRow key={index} data={data} testFilter={testFilter} testData={testData} setTestFilter={setTestFilter} graphSettings={graphSettings} setGraphSettings={setGraphSettings} />
        )) 
        : numMatchingTests ? <p>Page {pageNum} Outside Test Filter Match Group.</p> : <p> No Matching Tests Found. </p> }
        </div>
        </>
        : <p>Loading {pageNum}...</p> }
        </div>
     
    )
};

export default DataTable;
