import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import TestLogBrowser from './TestLogBrowser';
import Modal from 'react-bootstrap/Modal';

export const SubnavFiles = ({onChangeView}) => {

  const [show, setShow] = useState(false);

  const handleViewChange = () => onChangeView("TEST_LOG_BROWSER");
  
  return (
    <>
      <Button className="btn btn-primary mx-3" onClick={handleViewChange}>
      <PermMediaIcon /> 
      </Button>
    </>
  );
};