import React from 'react';
import Button from 'react-bootstrap/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export const SubnavSoftwareArtifactDownloader = ({onChangeView}) => {

  const handleViewChange = () => onChangeView("SOFTWARE_ARTIFACT_DOWNLOADER");
  
  return (
    <>
      <Button className="btn btn-primary mx-3" onClick={handleViewChange}>
      <CloudDownloadIcon /> 
      </Button>
    </>
  );
};