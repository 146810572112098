import React from 'react';
import Container from 'react-bootstrap/Container';

export const TestNavBar = ({ children }) => {
  return (
    <div className="horizontal-navbar d-grid gap-3 bg-secondary text-light py-3">
      <Container className="d-flex justify-content-center">{children}</Container>
    </div>
  );
};

export default TestNavBar;