import React, { useState, useEffect, useRef } from 'react';
import Accordion from '../components/Accordion.jsx';
import { getTestKits, getTestSchedules, stopTestSchedule, getTestKitDetailsRemote } from '../services/apiService.js';
import TestKitRow from '../components/controlPanel/testKitRow.jsx';
import LabelsRow from '../components/controlPanel/labelsRow.jsx';
import ScheduleCreation from '../components/controlPanel/scheduleCreation.jsx';
import '../styles/ControlPanel.css';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationPopup = ({ show, handleClose, handleConfirm }) => {
  return (

    <Modal show={show} onHide={handleClose} dialogClassName="confirm-modal">
      <Modal.Header closeButton>
        
      </Modal.Header>
      <Modal.Body>Are you sure you want to cancel the selected schedule(s)?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No, Go Back
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Yes, Cancel
        </Button>
      </Modal.Footer>
    </Modal>

  );
};

const ControlPanel = () => {
  const [testKits, setTestKits] = useState([]);
  const [testSchedules, setTestSchedules] = useState([]);
  const [testKitFilter, setTestKitFilter] = useState({});
  const [kitDetails, setKitDetails] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [showSchedulePopup, setShowSchedulePopup] = useState(false); // State to control the schedule creation popup visibility
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false); // State to control the confirmation popup visibility
  const [showBulkConfirmationPopup, setShowBulkConfirmationPopup] = useState(false); // State to control the bulk confirmation popup visibility
  const [popupData, setPopupData] = useState(null); // State to store data for the confirmation popup
  const [bulkActionStatus, setBulkActionStatus] = useState(""); // State to store the bulk action status
  const isMounted = useRef(true); // Track whether the component is mounted
  const [lastTestSchedules, setLastTestSchedules] = useState({});
  const [forceUpdate, setForceUpdate] = useState(false);

  const triggerUpdate = () => setForceUpdate(prev => !prev);

  useEffect(() => {
    async function fetchData() {
      try {
        console.log(`Fetching TK list...`);
        const apiData = await getTestKits();
        console.log("apiData: ", apiData);

        setTestKits(apiData || []);
        setCheckedItems(new Array(apiData.length).fill(false));
      } catch (error) {
        console.error('Error fetching API data: ' + error);
      }
    }

    fetchData();
  }, [testKitFilter]);

  useEffect(() => {
    async function fetchSchedules() {
      try {
        const schedules = {};
        for (const kit of testKits) {
          const scheduleData = await getTestSchedules({ tk_id: kit.id, includeTestRuns: true });
          schedules[kit.id] = scheduleData.testSchedules || []; // Ensure it's an array
        }
        setTestSchedules(schedules);
        
      } catch (error) {
        console.error('Error fetching API data: ' + error);
      }
    }
    if (testKits.length > 0) {
      fetchSchedules();
    }
  }, [testKits, forceUpdate]);

  useEffect(() => {
    isMounted.current = true;

    async function fetchDetails() {
      try {
        const details = {};
        for (const kit of filteredTestKits) {
          const kitData = await getTestKitDetailsRemote({ tk_id: kit.id });
          if (isMounted.current) { // Only update state if the component is still mounted
            details[kit.id] = kitData || []; // Ensure it's an array
          }
        }
        if (isMounted.current) {
          setKitDetails(details);
          console.log("details:, ", kitDetails);
        }
      } catch (error) {
        if (isMounted.current) {
          console.error('Error fetching API data: ' + error);
        }
      }
    }
    if (filteredTestKits.length > 0) {
      fetchDetails();
    }

    return () => {
      isMounted.current = false; // Cleanup function to set isMounted to false when the component unmounts
    };
  }, [testKits]);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        
        triggerUpdate(); // Trigger a re-render if there are changes
        console.log("5 second interval");
      } catch (error) {
        console.error('Error fetching API data: ' + error);
      }
    }, 5000); // Fetch updates every 5 seconds
  
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [testKits]);

  useEffect(() => {
    console.log("testKits updated: ", testKits);
  }, [testKits]);

  useEffect(() => {
    console.log("testSchedules updated: ", testSchedules);
  }, [testSchedules]);

  useEffect(() => {
    console.log("kitDetails updated: ", kitDetails);
  }, [kitDetails]);

  const handleClickProp = (e) => {
    e.stopPropagation();
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    setCheckedItems(updatedCheckedItems);
  };

  const handleChangeTestSchedule = async (tk_id, ts_id, new_status) => {
    try {
      console.log('tk_id: %d, ts_id: %d, status: %s', tk_id, ts_id, new_status);
      const result = await stopTestSchedule({ tk_id: tk_id, ts_id: ts_id, new_status: new_status });
      console.log('Test schedule changed:', result);

      const updatedSchedules = await getTestSchedules({ tk_id: tk_id, includeTestRuns: true });
      console.log('Updated schedules:', updatedSchedules); // Log the updated schedules

      setTestSchedules(prevSchedules => ({
        ...prevSchedules,
        [tk_id]: updatedSchedules.testSchedules || []
      }));

      console.log('State after update:', testSchedules); // Log the state after update
    } catch (error) {
      console.error('Error changing test schedule:', error);
    }
  };

  const updateTestSchedules = async (tk_id) => {
    try {
      const updatedSchedules = await getTestSchedules({ tk_id: tk_id, includeTestRuns: true });
      console.log('Add Schedule Updated schedules:', updatedSchedules); // Log the updated schedules

      setTestSchedules(prevSchedules => ({
        ...prevSchedules,
        [tk_id]: updatedSchedules.testSchedules || []
      }));

      console.log('State after update:', testSchedules); // Log the state after update
    } catch (error) {
      console.error('Error fetching API data: ' + error);
    }
  };

  const calculateTotals = (schedules) => {
    let totalTestsCompleted = 0;
    let totalTestsScheduled = 0;
    let totalLiquidVolume = 0;
    let totalTimeRemaining = 0;
    let estimatedEndTime = 0;
    //let totalTimeForTestsScheduled = 0;

    schedules.forEach(schedule => {
      totalTestsCompleted += schedule.tests_completed;
      totalTestsScheduled += schedule.tests_scheduled;
      totalLiquidVolume += (schedule.tests_scheduled - schedule.tests_completed) * schedule.delivery_vtbi;
      schedule.testRuns.forEach(testRun => {
        totalTimeRemaining += testRun.delivery_time_remaining;
      });
      estimatedEndTime = new Date(new Date().getTime() + totalTimeRemaining * 1000).toLocaleString();
      //totalTimeForTestsScheduled += (schedule.delivery_vtbi / schedule.delivery_rate) * (schedule.tests_scheduled - schedule.tests_completed);
    });

    return { totalTestsCompleted, totalTestsScheduled, totalLiquidVolume, estimatedEndTime };
  };

  const filteredTestKits = testKits
    .filter(k => k.id >= 45 && k.id <= 60)
    .sort((a, b) => a.id - b.id); // Sort by id in increasing order

  const accordionItems = filteredTestKits.map((k, index) => {
    const schedules = testSchedules[k.id] || [];
    const { totalTestsCompleted, totalTestsScheduled, totalLiquidVolume, estimatedEndTime } = calculateTotals(schedules);

    return {
      title: <TestKitRow key={k.id} data={k} schedules={schedules} kitDetails={kitDetails[k.id]} />,
      content: (
        <div onClick={handleClickProp}>
          <div className="schedule-totals">
            <span>Total Tests Completed: {totalTestsCompleted} / {totalTestsScheduled}</span>
            <span>Scheduled Liquid Volume: {totalLiquidVolume.toFixed(2)} ml</span>
            <span>Estimated End Time: {estimatedEndTime}</span>
          </div>
          <div className="schedule-table">
            <div className="schedule-table-header">
              <span>Status</span>
              <span>Needle Gauge</span>
              <span>Fluid Type</span>
              <span>Delivery Rate</span>
              <span>Delivery VTBI</span>
              <span>Tests Completed</span>
              <span>Schedule Controls</span>
            </div>
            {Array.isArray(testSchedules[k.id]) ? (
              testSchedules[k.id].map((schedule, i) => (
                <div key={i} className="schedule-table-row">
                  <span>{schedule.test_schedule_status}</span>
                  <span>{schedule.needle_gauge}</span>
                  <span>{schedule.fluid_type}</span>
                  <span>{schedule.delivery_rate}</span>
                  <span>{schedule.delivery_vtbi}</span>
                  <span>{schedule.tests_completed} / {schedule.tests_scheduled}</span>
                  <span className="schedule-table-actions">
                    <Tooltip title={`Cancel Schedule`} arrow>
                      <div >
                        <IconButton
                          aria-label="stop"
                          size="small"
                          onClick={() => handleShowConfirmationPopup({ tk_id: k.id, ts_id: schedule.id })}>
                          <StopIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title={`Pause Schedule`} arrow>
                      <div >
                        <IconButton
                          aria-label="pause"
                          size="small"
                          onClick={() => handleChangeTestSchedule(k.id, schedule.id, "PAUSED")}>
                          <PauseIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title={`Queue Schedule`} arrow>
                      <div >
                      <IconButton
                        aria-label="play"
                        size="small"
                        onClick={() => handleChangeTestSchedule(k.id, schedule.id, "SCHEDULED")}>
                        <PlayArrowIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </span>

                </div>
          ))
          ) : (
          <p>Loading...</p>
            )}
        </div>
        </div >
      )
    };
  });

const checkedCount = checkedItems.filter(item => item).length;

const handleBulkAction = (new_status) => {
  if (new_status === "CANCELED") {
    setBulkActionStatus(new_status);
    setShowBulkConfirmationPopup(true);
  } else {
    const testKitsToChange = checkedCount > 0
      ? filteredTestKits.filter((_, index) => checkedItems[index])
      : filteredTestKits;

    testKitsToChange.forEach(kit => {
      handleChangeTestSchedule(kit.id, null, new_status);
    });
  }
};

const handleShowConfirmationPopup = (data) => {
  setPopupData(data);
  setShowConfirmationPopup(true);
};

const handleCloseConfirmationPopup = () => {
  setShowConfirmationPopup(false);
  setPopupData(null);
};

const handleConfirmPopup = () => {
  if (popupData) {
    handleChangeTestSchedule(popupData.tk_id, popupData.ts_id, "CANCELED");
  }
  handleCloseConfirmationPopup();
};

const handleConfirmBulkPopup = () => {
  const testKitsToChange = checkedCount > 0
    ? filteredTestKits.filter((_, index) => checkedItems[index])
    : filteredTestKits;

  testKitsToChange.forEach(kit => {
    handleChangeTestSchedule(kit.id, null, bulkActionStatus);
  });
  setShowBulkConfirmationPopup(false);
};

// Filter the schedules based on the checked items
const filteredKitDetails = filteredTestKits
  .filter((_, index) => checkedItems[index])
  .map(kit => kitDetails[kit.id]);
  

return (
  <div>
    <div className="container">
      <div className="action-buttons">
        <button onClick={checkedCount > 0 ? () => setShowSchedulePopup(true) : null} className={`${checkedCount === 0 ? 'disabled no-hover' : ''}`} disabled={checkedCount === 0}> 
          {`Create ${checkedCount} Schedules`}
        </button>
        <button onClick={() => handleBulkAction("PAUSED")}>
          {checkedCount > 0 ? `Pause ${checkedCount}` : 'Pause All'}
        </button>
        <button onClick={() => handleBulkAction("SCHEDULED")}>
          {checkedCount > 0 ? `Resume ${checkedCount}` : 'Resume All'}
        </button>
        <button onClick={() => handleBulkAction("CANCELED")}>
          {checkedCount > 0 ? `Cancel ${checkedCount}` : 'Cancel All'}
        </button>
      </div>
      <LabelsRow />
      <div>
        {filteredTestKits.length > 0 ? (
          <Accordion items={accordionItems} checkedItems={checkedItems} onCheckboxChange={handleCheckboxChange} />
        ) : (
          <p>No data available</p>
        )}
      </div>

      <ScheduleCreation
        show={showSchedulePopup}
        onClose={() => setShowSchedulePopup(false)}
        kits={filteredTestKits.filter((_, index) => checkedItems[index])}
        kitDetails={filteredKitDetails}
        updateTestSchedules={triggerUpdate}
      />
    </div>
    <ConfirmationPopup
      show={showConfirmationPopup}
      handleClose={handleCloseConfirmationPopup}
      handleConfirm={handleConfirmPopup}
    />
    <ConfirmationPopup
      show={showBulkConfirmationPopup}
      handleClose={() => setShowBulkConfirmationPopup(false)}
      handleConfirm={handleConfirmBulkPopup}
    />
    <br></br><br></br>
  </div>
);
};

export default ControlPanel;
