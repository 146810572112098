import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig.js";
import { PageLayout } from "./components/PageLayout.jsx";
import { SubnavFiles } from "./components/SubnavFiles.jsx";
import {SubnavSoftwareArtifactDownloader} from "./components/SubnavSoftwareArtifactDownloader.jsx";
import { SubnavTable } from "./components/SubnavTable.jsx";
import { TestNavBar } from "./components/TestNavBar.jsx";
import { SoftwareArtifactDownloader } from "./components/SoftwareArtifactDownloader.jsx";
import { fetchUserSettings } from "./services/apiService.js";
import Button from "react-bootstrap/Button";
import Container from 'react-bootstrap/Container';
import "./styles/App.css";
import GraphContainer from './components/graphing/GraphContainer.jsx'; // Adjust the path as necessary
import TestLogBrowser from "./components/TestLogBrowser.jsx";
import DataTable from "./components/testData/DataTable.jsx";
import { MainNavBar } from "./components/NavBar.jsx";
import ControlPanel from "./pages/ControlPanel.js";
import MenderArtifactGenerator from "./pages/MenderArtifactGenerator.jsx";

const mode = process.env.REACT_APP_MODE;

// Check if REACT_APP_MODE is set to 'development'
const debugMode = mode === "development";
console.log(`Debug Mode: ${debugMode}`);

const MainContent = () => { 

    const [userSettings, setUserSettings] = useState(null);
   
    const [activeView, setActiveView] = useState("FLOWRATE_TABLE")

    

    const { accounts } = useMsal();
    const ms_account = accounts[0];

    const handleViewChange = (newView) => {
        setActiveView(newView);
    };

    useEffect(() => {
        if (ms_account && !userSettings) {
          console.log("ms_account -> ", ms_account)
          const ms_id = ms_account.localAccountId; 
          //const ms_name = account.name
          //const ms_username = account.username
          fetchUserSettings(ms_account).then(settings => setUserSettings(settings));
          console.log("userSettings: ", userSettings)
        }
    }, [ms_account, userSettings]);
    
    /*
<TestNavBar>
                
            
                <SubnavTable onChangeView={handleViewChange} />
                <SubnavFiles onChangeView={handleViewChange} />
                <SubnavSoftwareArtifactDownloader onChangeView={handleViewChange} />

                <Button onClick={() => handleViewChange("TEST_GRAPH")}>+ </Button>
            </TestNavBar>

            
    */
    return (
        <div>
            
            {debugMode ? (
                <Container fluid className="bg-dark">
                    {activeView === "FLOWRATE_TABLE" ? <DataTable userSettings={userSettings} /> : null}
                    {activeView === "TEST_LOG_BROWSER" ? <TestLogBrowser /> : null}
                    {activeView === "TEST_GRAPH" ? <GraphContainer /> : null}
                    {activeView === "SOFTWARE_ARTIFACT_DOWNLOADER" ? <SoftwareArtifactDownloader /> : null}
                </Container>
            ) : (
                <AuthenticatedTemplate>
                    <Container fluid className="bg-dark">
                        {activeView === "FLOWRATE_TABLE" && userSettings ? <DataTable userSettings={userSettings} /> : null}
                        {activeView === "TEST_LOG_BROWSER" ? <TestLogBrowser /> : null}
                        {activeView === "TEST_GRAPH" ? <GraphContainer /> : null}
                        {activeView === "SOFTWARE_ARTIFACT_DOWNLOADER" ? <SoftwareArtifactDownloader /> : null}
                    </Container>
                </AuthenticatedTemplate>
            )}

            {!debugMode && (
                <UnauthenticatedTemplate>
                    <h5 className="card-title">signed in.</h5>
                </UnauthenticatedTemplate>
            )}

           
        </div>
    );
};

export default function App() {
    return (
        <Router>
            <div className="App">
                <MainNavBar />
                <Routes>
                <Route path="/" element={
                    <PageLayout debugMode={debugMode}>
                        <MainContent />
                    </PageLayout>
                } />
                <Route path="/control-panel" element={
                    <PageLayout debugMode={debugMode}>
                        <ControlPanel />
                    </PageLayout>} />
                <Route path="/mender-artifact-generator" element={
                    <PageLayout debugMode={debugMode}>
                        <MenderArtifactGenerator />
                    </PageLayout>} />
                <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
        </Router>
        //<PageLayout debugMode={debugMode}>
        //    <MainContent />
        //</PageLayout>
    );
}
