import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';

const SwPickListDisplay = ({ swPickList }) => {
    if (!swPickList || Object.keys(swPickList).length === 0) {
        return null; // Return null if swPickList is empty
    }
    console.log("swPickList: ", swPickList)
    return (
        <Container className="mt-4 text-dark">
            <h3 >Requested Software Loads</h3>
            <Table className="mt-4 text-dark" striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Project Name</th>
                        <th>Source Type</th>
                        <th>Source</th>
                        <th>Pipeline</th>
                    </tr>
                </thead>
                <tbody className="text-dark">
                    {Object.keys(swPickList).map((projectName) => {
                        const { sourceType, source, pipeline } = swPickList[projectName];
                        return (
                            <tr className="text-dark" key={projectName}>
                                <td className="text-dark">{projectName}</td>
                                <td className="text-dark">{sourceType}</td>
                                <td className="text-dark" colSpan={sourceType === 'release' ? 2 : 1}>{source}</td>
                                {sourceType !== 'release' && <td className="text-dark">{pipeline}</td>}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </Container>
    );
};

export default SwPickListDisplay;
