// src/components/CardStatusBar.jsx
import React from 'react';
import '../../styles/ControlPanel.css';

const CardStatusBar = ({ data }) => {

    const getStatusClassName = (status) => {
        return status.toLowerCase() === 'delivering' ? 'text-status-green' : 'no program' ? '' : 'text-status-red';
    };

    const getErrorCodeClassName = (errorcode) => {
        return errorcode && errorcode.toLowerCase() !== '0x0' && errorcode !== '0' ? 'text-alert-red' : '';
    };

    function last_update() {

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;

        const ts = new Date(data.updatedAt)
        console.log('ts: ', ts);

        const elapsed = Math.round((Date.now() - ts.getTime()))
        console.log('elapsed: ', elapsed);

        if (elapsed < 0) {
            return "Unknown"
        }
        else if (elapsed < msPerMinute) {
            const secs = Math.round(elapsed / 1000);
            return <small>{secs} Secs Ago</small>
        }

        else if (elapsed < msPerHour) {
            const min = Math.round(elapsed / msPerMinute)
            return <small>{min} Min Ago</small>
        }

        else if (elapsed < msPerDay) {
            const hours = Math.round(elapsed / msPerHour)
            return <small>{hours} Hrs Ago</small>
        }

        else {
            const days = Math.round(elapsed / msPerDay)
            return <small>~{days} Days Ago</small>
        }

    }

    return (
        <div className="card-header-container">
            
                <div className="card-status-bar">
                    <div className="card-status-item">
                        <span className="label">TestKit ID</span>
                        <span className="value">TK-{data.id}</span>
                    </div>
                    <div className="card-status-item">
                        <span className="label">Serial #</span>
                        <span className="value">{data.infdev_sn}</span>
                    </div>
                    <div className="card-status-item">
                        <span className="label">Delivery Status</span>
                        <span className="value"><span className={getStatusClassName(data.delivery_status)}>{data.delivery_status || ""}</span></span>
                    </div>
                    <div className="card-status-item">
                        <span className="label">Alarm Status</span>
                        <span className="value">{data.alarm_status != 'None' ? data.alarm_status : ""}</span>
                    </div>
                    <div className="card-status-item">
                        <span className="label">Errors</span>
                        <span className="value"><span className={getErrorCodeClassName(data.errorcode)}>
                            {data.errorcode !== '0x0' && data.errorcode !== '0' ? data.errorcode : ""}
                        </span></span>
                    </div>
                    <div className="card-status-item">
                        <span className="label">Data Updated</span>
                        <span className="value">{last_update()}</span>
                        {/* Add more status items here */}
                    </div>
                </div>
                <div className="card-dev-status-bar">
                    <div className="card-dev-status-item">
                        <span>[i.MX7 <span className={data.usb_status_imx7 ? "safeText" : "dangerText"}>{data.usb_status_imx7 ? "↑" : "↓"}</span>]</span>
                    </div>
                    <div className="card-dev-status-item">
                        <span>[Debug <span className={data.usb_status_fpga ? "safeText" : "dangerText"}>{data.usb_status_fpga ? "↑" : "↓"}</span>]</span>
                    </div>
                    <div className="card-dev-status-item">
                        <span>[Bat <span>{data.usb_status_imx7 ? (String(data.battery_percent) + '%') : " -- "}</span>]</span>
                    </div>
                    <div className="card-dev-status-item">
                        <span>Client SSH: <a className="linkText" href={"ssh://amgistest@" + data.testcl_ip} >{data.testcl_ip}</a></span>
                    </div>
                    <div className="card-dev-status-item">
                        <span>Device SSH: <a className="linkText" href={"ssh://root@" + data.infdev_ip} >{data.infdev_ip || "Offline"}</a></span>
                    </div>
                </div>
            </div>
        
    );
};

export default CardStatusBar;