import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const AccordionGraph = ({ data, targetVolume, targetRate, testStart }) => {
  const svgRef = useRef();

  const targetRuntimeMs = (((targetVolume / targetRate) * 60 * 60) * 1000); // Corrected target runtime calculation
  const targetEndTime = new Date(testStart.getTime() + targetRuntimeMs);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const width = 600;
    const height = 400;
    const margin = { top: 20, right: 30, bottom: 30, left: 50 };

    // Calculate the time elapsed in minutes for each data point
    const dataWithElapsedTime = data.map(d => ({
      ...d,
      minutesSinceStart: (new Date(d.time) - testStart) / 60000 // Convert ms to minutes
    }));

    // Calculate the maximum time elapsed
    const maxMinutesSinceStart = d3.max(dataWithElapsedTime, d => d.minutesSinceStart);

    // Set up scales
    const x = d3
      .scaleLinear()
      .domain([0, maxMinutesSinceStart])
      .range([margin.left, width - margin.right]);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => Math.max(d.actual_vol_delivered_ml, d.volume_measured, targetVolume))])
      .nice()
      .range([height - margin.bottom, margin.top]);

    // Set up axes
    const xAxis = (g) =>
      g.attr('transform', `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0))
        .call(g => g.selectAll("text").attr("fill", "white"));

    const yAxis = (g) =>
      g.attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        .call(g => g.selectAll("text").attr("fill", "white"));

    // Clear previous content
    svg.selectAll('*').remove();

    // Draw axes
    svg.append('g').call(xAxis);
    svg.append('g').call(yAxis);

    // Y-axis label
    svg.append('text')
      .attr('class', 'y-axis-label')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(${margin.left / 3},${height / 2})rotate(-90)`)
      .attr('fill', 'white')
      .text('Volume (ml)');

    // Line generators
    const line1 = d3.line()
      .x(d => x(d.minutesSinceStart))
      .y(d => y(d.actual_vol_delivered_ml));

    const line2 = d3.line()
      .x(d => x(d.minutesSinceStart))
      .y(d => y(d.volume_measured));

    // Draw lines
    svg.append('path')
      .datum(dataWithElapsedTime)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 1.5)
      .attr('d', line1);

    svg.append('path')
      .datum(dataWithElapsedTime)
      .attr('fill', 'none')
      .attr('stroke', 'red')
      .attr('stroke-width', 1.5)
      .attr('d', line2);

    // Draw diagonal line for ideal delivery
    svg.append('line')
      .attr('x1', x(0))
      .attr('y1', y(0))
      .attr('x2', x((targetEndTime - testStart) / 60000))
      .attr('y2', y(targetVolume))
      .attr('stroke', 'green')
      .attr('stroke-width', 2)
      .attr('opacity', 1)
      .attr('stroke-dasharray', '2,2');

    // Add legend
    const legend = svg.append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(${width - margin.right - 500},${margin.top})`);

    legend.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 20)
      .attr('height', 20)
      .attr('fill', 'steelblue');

    legend.append('text')
      .attr('x', 30)
      .attr('y', 15)
      .attr('fill', 'white')
      .text('Actual Vol Delivered');

    legend.append('rect')
      .attr('x', 0)
      .attr('y', 30)
      .attr('width', 20)
      .attr('height', 20)
      .attr('fill', 'red');

    legend.append('text')
      .attr('x', 30)
      .attr('y', 45)
      .attr('fill', 'white')
      .text('Volume Measured');

    legend.append('rect')
      .attr('x', 0)
      .attr('y', 60)
      .attr('width', 20)
      .attr('height', 20)
      .attr('fill', 'green');

    legend.append('text')
      .attr('x', 30)
      .attr('y', 75)
      .attr('fill', 'white')
      .text('Target Volume');

    // Create vertical line and label elements
    const focusLine = svg.append('line')
      .attr('class', 'focusLine')
      .attr('stroke', 'white')
      .attr('stroke-width', 1.5)

      .style('display', 'none');

    const focusLabelRect = svg.append('rect')
      .attr('class', 'focusLabelRect')
      .attr('fill', 'white')
      .attr('stroke', 'black')
      .attr('rx', 1) // rounded corners
      .attr('ry', 1)
      .style('display', 'none');

    const focusLabel = svg.append('text')
      .attr('class', 'focusLabel')
      .attr('fill', 'black')  // Set the text color to black
      .attr('font-size', '12px')  // Set the text size to smaller
      .attr('text-anchor', 'middle')
      .attr('dy', '-1em')
      .style('display', 'none');

    // Add overlay for capturing mouse events
    svg.append('rect')
      .attr('class', 'overlay')
      .attr('width', width)
      .attr('height', height)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseover', () => {
        focusLine.style('display', null);
        focusLabel.style('display', null);
      })
      .on('mouseout', () => {
        focusLine.style('display', 'none');
        focusLabel.style('display', 'none');
        focusLabelRect.style('display', 'none');
      })
      .on('mousemove', function (event) {
        const [mouseX, mouseY] = d3.pointer(event);
        const x0 = x.invert(mouseX - margin.left);
        const timeAtX = new Date(testStart.getTime() + x0 * 60000); // Convert back to time
        const labelText = d3.timeFormat('%H:%M:%S')(timeAtX);

        focusLine
          .attr('x1', mouseX)
          .attr('y1', margin.top)
          .attr('x2', mouseX)
          .attr('y2', height - margin.bottom);

        focusLabel
          .attr('x', mouseX > width / 2 ? mouseX - 35 : mouseX + 35)  // Adjust position based on mouseX
          .attr('y', mouseY)  // Position the label 10 pixels above the top margin
          .text(d3.timeFormat('%H:%M:%S')(timeAtX));

        const textBBox = focusLabel.node().getBBox();
        focusLabelRect
          .attr('x', textBBox.x - 5)
          .attr('y', textBBox.y - 2)
          .attr('width', textBBox.width + 10)
          .attr('height', textBBox.height + 4)
          .style('display', null);

        focusLabel.style('display', null);
      });

  }, [data, targetVolume, targetRate, testStart]);

  return <svg ref={svgRef} width={600} height={400}></svg>;
};

export default AccordionGraph;