import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Row, Col, Container, Button } from 'react-bootstrap';
import "../../styles/MenderArtifactGenerator.css";

const GitlabBrowser = ({ gitData, setGitData, swBrowserSelection, setSwBrowserSelection, swPickList, setSwPickList }) => {
    const availableProjects = gitData?.['available-projects'] ?? {};
    const availableBranches = Array.isArray(gitData?.['available-branches']) ? gitData['available-branches'] : [];
    const availableReleases = Array.isArray(gitData?.['available-releases']) ? gitData['available-releases'] : [];
    const availableTags = Array.isArray(gitData?.['available-tags']) ? gitData['available-tags'] : [];
    const availablePipelines = Array.isArray(gitData?.['available-pipelines']) ? gitData['available-pipelines'] : [];

    const [releaseButtonText, setReleaseButtonText] = useState("No Releases Found.");
    const [branchButtonText, setBranchButtonText] = useState("No Branches Found");
    const [tagButtonText, setTagButtonText] = useState("No Tags Found");
    const [pipelineButtonText, setPipelineButtonText] = useState("No Pipelines Found");

    useEffect(() => {
        if (availableReleases.length > 0) {
            setReleaseButtonText(swBrowserSelection?.["source"] || "Select Release");
        } else {
            setReleaseButtonText("No Releases Found");
        }
        if (availableTags.length > 0) {
            setTagButtonText(swBrowserSelection?.["source"] || "Select Tag");
        } else {
            setTagButtonText("No Tags Found");
        }
        if (availableBranches.length > 0) {
            setBranchButtonText(swBrowserSelection?.["source"] || "Select Branch");
        } else {
            setBranchButtonText("No Branches Found");
        }

        if (availablePipelines.length > 0 && swBrowserSelection["source-type"] != "release") {
            setPipelineButtonText(swBrowserSelection?.["pipeline"] || "Select Pipeline");
        } else {
            setPipelineButtonText("No Pipelines Found");
        }
    }, [availableReleases, availableBranches, availablePipelines, availableTags, swBrowserSelection?.["source"], swBrowserSelection?.["source-type"]]);

    const handleProjectChange = (e) => {
        setSwBrowserSelection({
            ...swBrowserSelection,
            "project-name": e,
            "source-type": "", // Reset source-type when a new project is selected
            "source": "", // Reset source when a new project is selected
            "pipeline": "",
        });
    };

    const handleSourceTypeChange = (e) => {
        setSwBrowserSelection({
            ...swBrowserSelection,
            "source-type": e,
            "source": "", // Reset source when a new source type is selected
            "pipeline": "",
        });
    };

    const handleSourceChange = (e) => {
        setSwBrowserSelection({
            ...swBrowserSelection,
            "source": e,
            "pipeline": "",
        });
    };

    const handlePipelineChange = (e) => {
        setSwBrowserSelection({
            ...swBrowserSelection,
            "pipeline": e,
        });
    };

    const handleSubmit = () => {
        const { "project-name": projectName, "source-type": sourceType, "source": source, "pipeline": pipeline } = swBrowserSelection;
        const newEntry = { sourceType, source, pipeline };

        setSwPickList({
            ...swPickList,
            [projectName]: newEntry,
        });

        setSwBrowserSelection({
            "project-name": "",
            "source-type": "",
            "source": "",
            "pipeline": "",

        });

        setGitData({})
    };

    const isSubmitEnabled = swBrowserSelection["source-type"] && swBrowserSelection["source"];

    return (
        <Container className="d-flex flex-row justify-content-start" style={{ color: 'white', backgroundColor: '#333', padding: '20px'}}>
            <Form>
                <Row>
                    <Col className="p-0 m-1">
                        <Dropdown onSelect={handleProjectChange}>
                            <Dropdown.Toggle variant={swBrowserSelection?.["project-name"] ? "primary" : "info"} size="sm" id="dropdown-basic">
                                {swBrowserSelection?.["project-name"] ?? "Select Project"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {Object.keys(availableProjects).map((key) => (
                                    <Dropdown.Item key={key} eventKey={key}>
                                        {key}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>

                    {swBrowserSelection["project-name"] && (
                        <Col className="p-0 m-1">
                            <Dropdown onSelect={handleSourceTypeChange}>
                                <Dropdown.Toggle variant={swBrowserSelection?.["source-type"] ? "primary" : "info"} size="sm" id="dropdown-basic">
                                    {swBrowserSelection?.["source-type"] || "Select Source Type"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="release">Release</Dropdown.Item>
                                    <Dropdown.Item eventKey="branch">Branch</Dropdown.Item>
                                    <Dropdown.Item eventKey="tag">Tag</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    )}

                    {swBrowserSelection["source-type"] === "branch" && (
                        <Col className="p-0 m-1">
                            <Dropdown onSelect={handleSourceChange}>
                                <Dropdown.Toggle variant={swBrowserSelection?.["source"] ? "primary" : availableBranches.length == 0 ? "secondary" : "info"} size="sm" id="dropdown-basic">
                                    {branchButtonText}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {availableBranches.map((branch, index) => (
                                        <Dropdown.Item key={`branch-${index}`} eventKey={branch.name}>
                                            {branch.name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    )}


                    {swBrowserSelection["source-type"] === "tag" && (
                        <Col className="p-0 m-1">
                            <Dropdown onSelect={handleSourceChange}>
                                <Dropdown.Toggle variant={swBrowserSelection?.["source"] ? "primary" : availableTags.length == 0 ? "secondary" : "info"} size="sm" id="dropdown-basic">
                                    {tagButtonText}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {availableTags.map((tag, index) => (
                                        <Dropdown.Item key={`branch-${index}`} eventKey={tag.name}>
                                            {tag.name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    )}

                    {swBrowserSelection["source-type"] === "release" && (
                        <Col className="p-0 m-1">
                            <Dropdown onSelect={handleSourceChange}>
                                <Dropdown.Toggle variant={swBrowserSelection?.["source"] ? "primary" : availableReleases.length == 0 ? "secondary" : "info"} size="sm" id="dropdown-basic">
                                    {releaseButtonText}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {availableReleases.map((release, index) => (
                                        <Dropdown.Item key={`release-${index}`} eventKey={release.name}>
                                            {release.name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    )}

                    {availablePipelines.length > 0 && (swBrowserSelection["source-type"] === "branch" || swBrowserSelection["source-type"] == "tag") && (
                        <Col className="p-0 m-1">
                            <Dropdown onSelect={handlePipelineChange}>
                                <Dropdown.Toggle variant={swBrowserSelection?.["pipeline"] ? "primary" : availablePipelines.length == 0 ? "secondary" : "info"} size="sm" id="dropdown-basic">
                                    {pipelineButtonText}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {availablePipelines.map((pipeline, index) => (
                                        <Dropdown.Item key={`pipeline-${index}`} eventKey={pipeline.id}>
                                            {`${pipeline.id} (${pipeline["created_at"]})`}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    )}

                    {(swBrowserSelection["source-type"] === "release" || swBrowserSelection["pipeline"]) && swBrowserSelection["source"] && (
                        <Col className="p-0 m-1 align-items-center flex-shrink-0 ">
                            <Button
                                variant="success"
                                size="sm"
                                onClick={handleSubmit}
                                disabled={!isSubmitEnabled}>
                                ✔︎
                            </Button>
                        </Col>
                    )}
                </Row>
            </Form>
        </Container>
    );
};

export default GitlabBrowser;
