import React, { useState, useEffect } from 'react';
import '../../styles/TableData.css';
import TestHeader from './TestHeader.jsx'; 
import PreviewGraphContainer from '../graphing/PreviewGraphContainer.jsx';
import DataNavCell from './DataNavCell.jsx';
import DropPreviewThumbnail from './DropPreviewThumbnail.jsx'
import { getTestPreviewGraphData } from '../../services/apiService.js';

const EXPRESS_URL = process.env.REACT_APP_EXPRESS_URL;
const MAIN_URL = process.env.REACT_APP_MAIN_URL;

const componentMapping = {
    'testHeader': TestHeader,
    'previewGraph': PreviewGraphContainer,
    'dataNavCell': DataNavCell,
    'DropPreviewThumbnail': DropPreviewThumbnail
};

const DataRow = ({ data, testData, graphSettings, setGraphSettings, testFilter, setTestFilter }) => {

    const [imageList, setImageList]  = useState([])
    const [imageFileInfo, setImageFileInfo] = useState({})
    const [activeImageIndex, setActiveImageIndex] = useState(0)

    const debugRow = (Number(data.parentTkId) > 55)
    const errorRow = (data.errorcode && data.errorcode !== "0x0")
    const alarmRow = (data.alarm_status && data.alarm_status !== "None")
    const normalColor =  "rgba(39, 147, 209, .1)"
    const excludeColor = "goldenrod"
    const alarmColor = "darkorange"
    const errorColor = "darkred"
    const debugTextColor = {color:'#666666'}
    const debugBgColor = {backgroundColor:'#AAAAAA'}
    
    const headerColor = errorRow ? errorColor : alarmRow ? alarmColor : data.exclude_flag ? excludeColor : normalColor

    const [timeSeriesData, setTimeSeriesData] = useState([]);

    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
    const throttle = async () => {await sleep(50)}

    useEffect(() => {
        async function fetchData() {
            try {
                const apiData = await getTestPreviewGraphData({ tr_id: data.id });
                setTimeSeriesData(apiData);
            } catch (error) {
                console.error('Error fetching API data:', error);

            }
        }

        if (data && !isNaN(data.id) ) {
            fetchData()
        }
    }, [data, testData, testFilter]);


    useEffect(() => {
        
        const tr_id = data.id;
        const test_series = data.test_series;
        const test_number = data.test_number;
        const infdev_sn = data.infdev_sn;

        const testLogDir = `/series-${test_series}/FR-${tr_id}_${test_series}${test_number}_${infdev_sn}`

        const fetchDirContents = async () => {
            const fetchURL = `https://${EXPRESS_URL}/dropImages/${encodeURIComponent(testLogDir)}`;
            console.log("Fetching:", fetchURL);
            try {
            const response = await fetch(fetchURL, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            console.log('Request Made to express server.');

            const responseData = await response.json();
            console.log('Image List received:', responseData);
            
            setImageList(responseData.files);
            setImageFileInfo(responseData.files[activeImageIndex])

        } catch (error) {
            console.error("Error fetching directory contents:", error);
        }
        };

        if (testLogDir) { fetchDirContents() };

    }, [data]);

    const rowHeaderTitle = (data) => {
        
        return (

            <div className="header-cell" style={{gridColumnStart: 1, gridColumnEnd: 4, gridRowStart: 1, gridRowEnd: 2, backgroundColor: headerColor}}>
                <TestHeader data={data} />
            </div>
        ); 
    };

    const rowHeaderDelivery = (data) => {
        
        return (
            <div className = "header-cell" style={{gridColumnStart: 4, gridColumnEnd: 5, gridRowStart: 1, gridRowEnd: 2, backgroundColor: headerColor}}>
                <span className="test-header-cell" >
                    <div className="test-header-content" style={debugRow ? {...debugBgColor, ...debugTextColor} : {}}>
                        <span className="vertical-cell">
                            <div style={debugRow ? debugTextColor : {}}>
                                <span className="label-text"><strong style={{color: "dodgerblue"}}>▶︎</strong> MEASURED:</span>{data.delivery_vol_delivered_raw || ""}<span className="unit-text">mL</span>
                            </div>
                            <div style={debugRow ? debugTextColor : {}}>
                            <span className="label-text"><strong style={{color: "firebrick"}}>▶︎</strong> BALANCE:</span>{data.actual_vol_delivered_ml|| ""}<span className="unit-text">mL</span>
                            </div>
                        </span>
                    </div>
                </span>
            </div>
        ); 
    };

    const rowHeaderAccuracy = (data) => {
        
        return (
            <div className = "header-cell" style={{gridColumnStart: 5, gridColumnEnd: 6, gridRowStart: 1, gridRowEnd: 2, backgroundColor: headerColor}}>
                <span className="test-header-cell" >
                    <div className="test-header-content" style={debugRow ? {...debugBgColor, ...debugTextColor} : {}}>
                        <span className="vertical-cell">
                            <div style={debugRow ? debugTextColor : {}}>
                            <span className="label-text"><strong style={{color: "dodgerblue"}}>▶︎</strong> MEAS ERROR:</span>{data.measurement_error_percent || ""}<span className="unit-text">%</span>
                            </div>
                            <div style={debugRow ? debugTextColor : {}}>
                            <span className="label-text"><strong style={{color: "firebrick"}}>▶︎</strong> TOTAL ERROR:</span>{data.total_error_percent|| ""}<span className="unit-text">%</span>
                            </div>
                        </span>
                    </div>
                </span>
            </div>
        ); 
    };

    const rowHeaderDrops = (data) => {
        
        return (
            <div className = "header-cell" style={{gridColumnStart: 6, gridColumnEnd: 7, gridRowStart: 1, gridRowEnd: 2, backgroundColor: headerColor}}>
                <span className="test-header-cell" >
                    <div className="test-header-content" style={debugRow ? {...debugBgColor, ...debugTextColor} : {}}>
                        <span className="vertical-cell">
                            <div style={debugRow ? debugTextColor : {}}>
                                <span className="label-text">DROPS MEASURED:</span>{data.drops_measured || ""}<span className="unit-text"></span>
                            </div>
                            <div style={debugRow ? debugTextColor : {}}>
                                <span className="label-text">DROP VOL AVG:</span>{data.drops_vol_avg|| ""}<span className="unit-text">μL</span>
                            </div>
                        </span>
                    </div>
                </span>
            </div>
        ); 
    };

    const rowBodyNav = (data) => {
        
        return (
            <div style={{backgroundColor: "rgba(0,0,0,.25)", height: "100%", gridColumnStart: 1, gridColumnEnd: 2, gridRowStart: 2, gridRowEnd: 4 }}>
                <DataNavCell data={data} testFilter={testFilter} setTestFilter={setTestFilter} graphSettings={graphSettings} setGraphSettings={setGraphSettings} timeSeriesData={timeSeriesData}/>
            </div>
            ); 
    };

    const rowBodyStats = (data) => {
        
        return (
            <div style={{gridColumnStart: 2, gridColumnEnd: 4, gridRowStart: 2, gridRowEnd: 4, height: "100%"}}>
                <div style={{display: "flex", flexDirection: "row", width: "100%", height: "25%"}}>
                    <div className="data-cell"><span className="label-text">IV Set:</span>{data.test_set_number|| ""}<span className="unit-text"></span></div>
                    <div className="data-cell"><span className="label-text">Needle:</span>{data.needle_gauge|| ""}<span className="unit-text">ga</span></div>
                    <div className="data-cell"><span className="label-text">Fluid:</span>{data.fluid_type|| ""}<span className="unit-text"></span></div>
                </div>
                <div style={{display: "flex", flexDirection: "row", width: "100%", height: "25%"}}>    
                    <div className="data-cell"><span className="label-text">Errors:</span><p style={{color: errorColor}}>{data.errorcode|| ""}</p><span className="unit-text"></span></div>
                    <div className="data-cell"><span className="label-text">Alarms:</span><p style={{color: alarmColor}}>{data.alarm_status|| ""}</p><span className="unit-text"></span></div>
                    <div className="data-cell"><span className="label-text">NoFlow:</span>{data.max_noflow_time_seconds|| ""}<span className="unit-text">sec</span></div>
                    <div className="data-cell"><span className="label-text">Boundary Ratio:</span>{data.droplog_boundary_ratio_avg ? parseFloat(data.droplog_boundary_ratio_avg).toFixed(4) : ""}</div>
                </div>
                <div className="scroll-cell overflow-auto" style={{maxHeight: "50%"}}><span className="label-text">Note:</span>{data.post_test_note|| ""}</div>
            </div>
        ); 
    };

    const rowBodyGraphDelivery = (timeSeriesData) => {
        
        return (
            <div className = "component-cell" style={{gridColumnStart: 4, gridColumnEnd: 5, gridRowStart: 2, gridRowEnd: 4 }}>
                <PreviewGraphContainer data={data} layout={{cellName: "deliveryGraph"}} timeSeriesData={timeSeriesData} />
            </div>
        ); 
    };


    const rowBodyGraphAccuracy = (timeSeriesData) => {
        
        return (
            <div className = "component-cell" style={{gridColumnStart: 5, gridColumnEnd: 6, gridRowStart: 2, gridRowEnd: 4 }}>
                <PreviewGraphContainer data={data} layout={{cellName: "accuracyGraph"}} timeSeriesData={timeSeriesData} />
            </div>
        ); 
    };


    const rowBodyDropPreview = (timeSeriesData) => {
        
        return (
            <div className = "component-cell" style={{gridColumnStart: 6, gridColumnEnd: 7, gridRowStart: 2, gridRowEnd: 4 }}>
               <DropPreviewThumbnail data={data} timeSeriesData={timeSeriesData} imageList={imageList} imageFileInfo={imageFileInfo} setImageFileInfo={setImageFileInfo} activeImageIndex={activeImageIndex} setActiveImageIndex={setActiveImageIndex}/> 
            </div>
        ); 
    };
    

    return (
        <div className="data-row" style={debugRow ? {backgroundColor:'black', color:'#666666'} : {}}>
            {rowHeaderTitle(data)}
            {rowHeaderDelivery(data)}
            {rowHeaderAccuracy(data)}
            {rowHeaderDrops(data)}
            {rowBodyNav(data)}
            {rowBodyStats(data)}
            {rowBodyGraphDelivery(timeSeriesData)}
            {rowBodyGraphAccuracy(timeSeriesData)}
            {rowBodyDropPreview(timeSeriesData)}
        </div>
    );
};


export default DataRow;


/*

   {rowBodyDropPreview(timeSeriesData)}

*/
