import React from 'react';
import '../../styles/ControlPanel.css';

const LabelsRow = () => {
  return (
    <div className="labels-row">
      <span className="label-item"></span>
      <span className="label-item"></span>
      <span className="label-item">Test Kit ID</span>
      <span className="label-item">Serial Number</span>
      <span className="label-item">Delivery Status</span>
      <span className="label-item">Time Remaining</span>
      <span className="label-item">Balance</span>
      <span className="label-item">Add Schedule</span>
      <span className="label-item">More</span>
    </div>
  );
};

export default LabelsRow;