import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import GraphContainer from './GraphContainer.jsx';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TestHeader from '../testData/TestHeader.jsx'; 
import RawDataViewer from '../testData/RawDataViewer.jsx'; 
import CommandHistory from '../testData/CommandHistory.jsx'; 
import DropGallery from "../testData/DropGallery.jsx";
import TestLogBrowser from '../TestLogBrowser.jsx'; 
import { getTestRunTimeSeries } from '../../services/apiService.js';

import '../../styles/Graphing.css';
import '../../styles/TableData.css';
import '../../styles/TableData.css';

const EXPRESS_URL = process.env.REACT_APP_EXPRESS_URL;
const MAIN_URL = process.env.REACT_APP_MAIN_URL;

const TestGraphBrowserModal = ({ graphSettings,
    setGraphSettings,
    data,
    timeSeriesData,
    id, }) => {
    
    const [show, setShow] = useState(false);
    const [modalTab, setModalTab] = useState("mainGraph")
    const [activeImageIndex, setActiveImageIndex] = useState(0)
    const [fullLog, setFullLog] = useState({})
    const [imageList, setImageList]  = useState([])
    const [imageFileInfo, setImageFileInfo] = useState({})

    const testLogDir = `series-${data.test_series}/FR-${id}_${data.test_series}${data.test_number}_${data.infdev_sn}`;

    useEffect(() => {
        async function fetchData() {
            try {
                const apiData = await getTestRunTimeSeries({ tr_id: id });
                console.log('API Data:', apiData);
                setFullLog(apiData);
            } catch (error) {
                console.error('Error fetching API data:', error);
            }
        }

        if (id && show) {
            fetchData();
        }
    }, [id, show]);


    useEffect(() => {
    
        const fetchDirContents = async () => {
            const fetchURL = `https://${EXPRESS_URL}/dropImages/${encodeURIComponent(testLogDir)}`;
            console.log("Fetching:", fetchURL);
            try {
            const response = await fetch(fetchURL, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            console.log('Request Made to express server.');

            const responseData = await response.json();
            console.log('Image List received:', responseData);
            
            setImageList(responseData.files);
            setImageFileInfo(responseData.files[activeImageIndex])

        } catch (error) {
            console.error("Error fetching directory contents:", error);
        }
        };

        if (testLogDir) { fetchDirContents() };

    }, [testLogDir, id, show]);


    const handleShow = async () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setFullLog({});
    };

    const handleTabRawData = () => { setModalTab("rawData") };
    const handleTabMainGraph = () => { setModalTab("mainGraph") };
    const handleTabAccuracyGraph = () => { setModalTab("accuracyGraph") };
    const handleTabDropGraph = () => { setModalTab("dropGraph") };
    const handleTabDropGallery = () => { setModalTab("dropGallery") };
    const handleTabLogBrowser = () => { setModalTab("logBrowser") };
    const handleTabCommandHistory = () => { setModalTab("commandHistory") };
   
    return (

        <>

            <Button type="button" variant="outline-secondary" className="btn btn-square-md data-button" onClick={handleShow}>
                <AssessmentIcon />
            </Button>

            <Modal show={show} backdrop={true} className="text-light" style={{
                maxWidth: 'none',
                '--bs-modal-width': '90vw',
                minHeight: "90vh",
                left: '0',
            }}>
                <Modal.Header className="test-modal-header text-light ">
                   
                    <TestHeader data={data}/>
                    <span className="test-header-cell" >
                    <div className="test-header-content">
                        <span className="vertical-cell">
                            <div >
                                <span className="label-text"><strong style={{color: "dodgerblue"}}>▶︎</strong> MEASURED:</span>{data.delivery_vol_delivered_raw || ""}<span className="unit-text">mL</span>
                            </div>
                            <div >
                            <span className="label-text"><strong style={{color: "firebrick"}}>▶︎</strong> BALANCE:</span>{data.actual_vol_delivered_ml|| ""}<span className="unit-text">mL</span>
                            </div>
                        </span>
                    </div>
                </span>
                <span className="test-header-cell" >
                    <div className="test-header-content">
                    <span className="vertical-cell">
                            <div>
                            <span className="label-text"><strong style={{color: "dodgerblue"}}>▶︎</strong> MEAS ERROR:</span>{data.measurement_error_percent || ""}<span className="unit-text">%</span>
                            </div>
                            <div >
                            <span className="label-text"><strong style={{color: "firebrick"}}>▶︎</strong> TOTAL ERROR:</span>{data.total_error_percent|| ""}<span className="unit-text">%</span>
                            </div>
                        </span>
                        </div>
                </span>
                <span className="test-header-cell" >
                    <div className="test-header-content">
                    <span className="vertical-cell">
                            <div >
                                <span className="label-text">DROPS MEASURED:</span>{data.drops_measured || ""}<span className="unit-text"></span>
                            </div>
                            <div >
                                <span className="label-text">DROP VOL AVG:</span>{data.drops_vol_avg|| ""}<span className="unit-text">μL</span>
                            </div>
                        </span>
                        </div>
                </span>
                   
                    <button type="button" class="btn-close btn-close-white" aria-label="Close" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body className="bg-dark text-light d-flex justify-content-around text-center" style={{ minHeight: "75vh", width: "100%", padding: "0", margin: "0", overflowX: "hidden", overflowY: "scroll" }}>

                    {modalTab == "mainGraph" || modalTab ==  "accuracyGraph" || modalTab == "dropGraph" ?
                        <GraphContainer tr_id={id}
                            graphSettings={graphSettings}
                            setGraphSettings={setGraphSettings}
                            modalTab={modalTab}
                            setModalTab={setModalTab}
                            activeImageIndex={activeImageIndex}
                            setActiveImageIndex={setActiveImageIndex}
                            data={fullLog}
                        />
                        : modalTab == "rawData" ?
                            <RawDataViewer data={fullLog} style={{width: "80vw"}}/>
                        : modalTab == "commandHistory" && fullLog.commandHistory ?
                        <CommandHistory data={fullLog.commandHistory} style={{width: "80vw"}}/>
                        : modalTab == "dropGallery" ? <div className="image-full-container"><DropGallery fullLog={fullLog}
                                timeSeriesData={timeSeriesData}
                                activeImageIndex={activeImageIndex}
                                setActiveImageIndex={setActiveImageIndex}
                                imageList={imageList}
                                setImageFileInfo={setImageFileInfo}
                                imageFileInfo={imageFileInfo}
                            /></div>
                        : modalTab == "logBrowser" ? <TestLogBrowser instanceId={testLogDir} startingDirPath={testLogDir} />
                                : <></>
                    }

                </Modal.Body>
                <Modal.Footer className="d-flex bg-dark text-light modal-footer" style={{ width: "100%" }}>
                    <Button variant={modalTab == "mainGraph" ? "primary" : "secondary"} onClick={handleTabMainGraph}>
                        Delivery Graph
                    </Button>
                    <Button variant={modalTab == "accuracyGraph" ? "primary" : "secondary"} onClick={handleTabAccuracyGraph}>
                        Accuracy Graph
                    </Button>
                    <Button variant={modalTab == "dropGraph" ? "primary" : "secondary"} onClick={handleTabDropGraph}>
                        Drop Graph
                    </Button>
                    <Button variant={modalTab == "dropGallery" ? "primary" : "secondary"} onClick={handleTabDropGallery}>
                        Drop Images
                    </Button>
                    <Button variant={modalTab == "logBrowser" ? "primary" : "secondary"} onClick={handleTabLogBrowser}>
                        Log Files
                    </Button>
                    <Button variant={modalTab == "commandHistory" ? "primary" : "secondary"} onClick={handleTabCommandHistory}>
                       Command History
                    </Button>
                    <Button variant={modalTab == "rawData" ? "primary" : "secondary"} onClick={handleTabRawData}>
                        Raw Data
                    </Button>
                    <Button variant="info-outline" disabled="true">
                        Add Note
                    </Button>

                </Modal.Footer>
            </Modal>

        </>
    );
};

export default TestGraphBrowserModal;

//